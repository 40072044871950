// Utilities
import { env } from '@/env'
import router from '@/router'
import axios from '@/api/axios'
import { defineStore } from 'pinia'
import * as CryptoJS from 'crypto-js'
import Me from '@/plugins/interfaces/Me'

export default defineStore('auth', 
{
    state: () => ({ 
        me: {} as Me,
        loggedIn: (localStorage.getItem(env.tokenName) !== null),
        credentials: 
        {
            email: '',
            password: ''
        },
        changeCredentials:
        {
            email: '',
            password: '',
            password_confirmation: '',
            token: ''
        },
        responseStatus:
        {
            message: '',
            success: true,
        },
        loading: false,
        processing: false,
        passwordVisible: false,
        passwordIsReset: false,
        passwordResetSent: false,
        loginFailCount: 0,
    }),

    getters:
    {
        getMe: (state) => state.me,
        isLoading: (state) => state.loading,
        isProcessing: (state) => state.processing,
        isUserLoggedIn: (state) => state.loggedIn,
        isPasswordVisible: (state) => state.passwordVisible,
    },

    actions: 
    {
        async login()
        {
            this.processing = true

            try
            {    
                const response = await axios.post('/auth/login', this.credentials)

                if(response.status === 200)
                {
                    localStorage.setItem(env.tokenName, response.data.token)

                    const userPermissions = {
                        roles: response.data.roles,
                        permissions: response.data.permissions
                    }

                    this.me.roles = response.data.roles
                    this.me.permissions = response.data.permissions

                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userPermissions), env.cryptoKey).toString()

                    localStorage.setItem(env.encryptedDataName, encryptedData)

                    router.push({ path: '/' })

                    this.processing = false

                    this.loggedIn = true

                    this.loginFailCount = 0

                    this.responseStatus.success = true
                }

                return response
            }
            catch(error:any) 
            {
                this.processing = false
                this.responseStatus = error.response.data
                
                if(error.response.status === 401) this.loginFailCount++
                
                throw error.message
            }
        },

        async logout()
        {
            this.processing = true

            try
            {
                const response = await axios.post('/auth/logout')

                if(response.status === 200)
                {
                    this.loggedIn = false

                    localStorage.removeItem(env.tokenName)

                    localStorage.removeItem(env.encryptedDataName)

                    router.push({ path: '/login' })

                    this.processing = false
                }

                return response
            }                  
            catch(error)
            {
                this.processing = false

                throw error
            }
        },

        async sendPasswordResetLink()
        {
            this.processing = true
            this.passwordResetSent = false

            try
            {    
                const response = await axios.post('/auth/lost-password', {'email': this.credentials.email})

                this.processing = false
                this.responseStatus = response.data
                this.passwordResetSent = response.data.success
                
                return response
            }
            catch(error:any) 
            {
                this.processing = false
                this.responseStatus = error.response.data

                throw error.message
            }
        },

        async getUserByPasswordResetToken(token:string, email:string)
        {
            this.processing = true

            try
            {    
                const response = await axios.get(`/auth/change-password/${token}/?email=${email}`)

                console.log(response.data)

                if(response.status === 200)
                {
                    this.changeCredentials.token = token
                    this.changeCredentials.email = response.data.user.email
                }

                this.processing = false

                return response
            }
            catch(error:any) 
            {
                this.processing = false
                this.responseStatus = error.response.data

                throw error.message
            }
        },

        async changePassword()
        {
            this.processing = true
            this.passwordIsReset = false

            try
            {    
                const response = await axios.post('/auth/change-password', this.changeCredentials)

                this.processing = false
                this.responseStatus = response.data
                this.passwordIsReset = response.data.success
                
                return response
            }
            catch(error:any) 
            {
                this.processing = false
                this.responseStatus = error.response.data

                throw error.message
            }
        },

        async authMe()
        {
            this.processing = true

            try
            {
                const response = await axios.get('/auth/me')

                if(response.status === 200)
                {
                    this.me = response.data

                    const userPermissions = {
                        roles: response.data.roles,
                        permissions: response.data.permissions
                    }

                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userPermissions), env.cryptoKey).toString()

                    if(localStorage.getItem(env.encryptedDataName) !== encryptedData)
                    {
                        localStorage.setItem(env.encryptedDataName, encryptedData)
                    }
                }

                return response
            }
            catch(error)
            {
                this.processing = false

                throw error
            }
        }
    }
})