<script setup lang="ts">
    import useAuthStore from '@/stores/authentication/auth'
    import TasMainnav from '@/layouts/navigations/tasmainnav.vue'
    import TasTopBarnav from '@/layouts/navigations/topbarnav.vue';

    const authStore = useAuthStore();

</script>

<template>
  <v-app>
    <tas-mainnav v-if="authStore.loggedIn"/>
    <tas-top-barnav v-if="authStore.loggedIn"/>
    
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>