const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/authentication/login.vue'),
    },

    {
        path: '/login/lost-password',
        name: 'LostPassword',
        component: () => import('@/views/authentication/LostPassword.vue'),
    },

    {
        path: '/login/change-password/:token',
        name: 'ChangePassword',
        component: () => import('@/views/authentication/ChangePassword.vue'),
    },
];

export default routes;