// Composables
import { env } from '@/env'
import { cloneDeep } from 'lodash'
import * as CryptoJS from 'crypto-js'
import useAuthStore from '@/stores/authentication/auth'

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = []

// Use import.meta.glob to find and import route modules
// const routeModules: Record<string, () => Promise<any>> = import.meta.glob('@/views/**/*.routes.ts')

// for (const modulePath in routeModules) 
// {
//     const moduleRoutes = (await routeModules[modulePath]()).default as RouteRecordRaw[];
//     routes.push(...moduleRoutes)
// }

import authenticationRoutes from "@/views/authentication/authentication.routes"
import catchErrorRoutes from "@/views/catcherror.routes"
import classesRoutes from "@/views/classes/classes.routes"
import dashboardRoutes from "@/views/dashbooard/dashboard.routes"
import libraryRoutes from "@/views/library/library.routes"
import scheduleRoutes from "@/views/schedules/schedule.routes"
import reportsRoutes from "@/views/reports/reports.routes"
import settingsRoutes from "@/views/settings/settings.routes"

routes.push(
    ...authenticationRoutes,
    ...classesRoutes,
    ...dashboardRoutes,
    ...libraryRoutes,
    ...scheduleRoutes,
    ...reportsRoutes,
    ...settingsRoutes,
    ...catchErrorRoutes,
)

const router = createRouter({ history: createWebHistory(), routes, })

router.beforeEach((to, from, next) => 
{    
    const userAuth = useAuthStore()

    let user = cloneDeep(userAuth.getMe)

    if(user.me === undefined)
    {
        const encryptedData = localStorage.getItem(env.encryptedDataName)

        if (encryptedData) 
        {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, env.cryptoKey)
            const userData = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8))

            user.roles = userData.roles
            user.permissions = userData.permissions
        }
    }

    const requiredRoles = to.meta.roles as []
    const requiredPermissions = to.meta.permissions as []

    if (to.name !== 'Login' && !userAuth.isUserLoggedIn) 
    {   
        console.log("Page Name:", to.name)

        if(['LostPassword','ChangePassword'].indexOf(to.name as string) != -1)
            next();
        else
            next({ path: '/login' }) // Redirect to the login route
    }
    else 
    {
        if(to.name !== 'Unauthorized' && userAuth.isUserLoggedIn && !user.roles.includes('Administrator'))
        {   
            const hasRequiredRoles = requiredRoles !== undefined && requiredRoles.length ? user.roles.some(role => requiredRoles.includes(role as never)) : true

            const hasRequiredPermissions = requiredPermissions !== undefined && requiredPermissions.length ? user.permissions.some(permission => requiredPermissions.includes(permission as never)) : true

            if (!hasRequiredRoles || !hasRequiredPermissions) 
            {
                // Redirect the user to an unauthorized page or handle access denial
                next({ name: 'Unauthorized' })
                return
            }
        }

        if (to.name === 'Login' && userAuth.isUserLoggedIn) 
        {
            next({ path: '/' }) // Redirect to root landing page 
        }
        else 
        {
            next(); // Continue navigating
        }
    }
})

export default router